import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useState } from 'react'
import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import VerificationStepEntity from '../../../../dto/VerificationStepEntity.interface'
import { VerificationStepStatus } from '../../../../enums/VerificationStepStatus'
import userStore from '../../../../stores/user.store'
import { VerificationStore } from '../../../../stores/verificaton.store'
import { onPromise } from '../../../../util/formHelper'
import { checkRedoNavigate } from '../../../../util/navigateHelper'
import CardScrollable from '../../../CardScrollable/CardScrollable'
import TextInput from '../../../inputs/TextInput'
import InputOptionType from '../../../../enums/InputOptionType'
import configStore from '../../../../stores/config.store'
import DropdownSelected from '../../../inputs/Dropdowns/DropdownSelected'

interface Props {
  verificationStore: VerificationStore
}

const addressProtocols = [
  { label: 'Bitcoin (BTC)', value: 'btc' },
  { label: 'Ethereum (ETH)', value: 'eth' },
  { label: 'Binance Smart Chain (BNB)', value: 'bnb' },
  { label: 'Solana (SOL)', value: 'sol' },
  { label: 'Ripple (XRP)', value: 'xrp' },
  { label: 'Avalanche (AVAX)', value: 'avax' },
  { label: 'Tron (TRX)', value: 'trx' },
  { label: 'Polygon (MATIC)', value: 'matic' },
  { label: 'Litecoin (LTC)', value: 'ltc' },
  { label: 'Bitcoin Cash (BCH)', value: 'bch' },
  { label: 'Stellar (XLM)', value: 'xlm' },
  { label: 'Bitcoin SV (BSV)', value: 'bsv' },
  { label: 'MultiversX (EGLD)', value: 'egld' },
  { label: 'Algorand (ALGO)', value: 'algo' },
  { label: 'Flow (FLOW)', value: 'flow' },
  { label: 'Klaytn (KLAY)', value: 'klay' },
  { label: 'Bitcoin Gold (BTG)', value: 'btg' },
  { label: 'Zcash (ZEC)', value: 'zec' },
  { label: 'Dash (DASH)', value: 'dash' },
  { label: 'Harmony (ONE)', value: 'one' },
  { label: 'Verge Currency (XVG)', value: 'xvg' },
  { label: 'Provenance (HASH)', value: 'hash' },
  { label: 'SUI', value: 'sui' },
  { label: 'EOS', value: 'eos' },
  { label: 'Dogecoin (DOGE)', value: 'doge' },
  { label: 'Cardano (ADA)', value: 'ada' },
  { label: 'Conflux (CFX)', value: 'cfx' },
  { label: 'Cosmos (ATOM)', value: 'atom' },
  { label: 'Ethereum Classic (ETC)', value: 'etc' },
  { label: 'Celo (CELO)', value: 'celo' },
  { label: 'Fantom (FTM)', value: 'ftm' },
  { label: 'Moonbeam (GLMR)', value: 'glmr' },
  { label: 'Cronos (CRO)', value: 'cro' },
  { label: 'Everscale (EVER)', value: 'ever' },
  { label: 'Filecoin (FIL)', value: 'fil' },
  { label: 'Hedera (HBAR)', value: 'hbar' },
  { label: 'Tezos (XTZ)', value: 'xtz' },
  { label: 'Coreum', value: 'coreum' }
]

const Wallet = ({ verificationStore }: Props): ReactElement => {
  const [loading, setLoading] = useState(false)
  const methods: UseFormReturn<VerificationStepEntity, any> =
    useForm<VerificationStepEntity>({ mode: 'onChange' })
  const navigate = useNavigate()

  useEffect(() => {
    const load = async (): Promise<void> => {
      setLoading(true)
      await userStore.getMe().catch((err) => console.log(err))

      await verificationStore.loadWallet().catch((err) => console.log(err))
      setLoading(false)
    }

    load().catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    if (verificationStore.wallet) {
      methods.setValue('wallet', verificationStore.wallet)
    }

    if (verificationStore.wallet?.walletProtocol) {
      const selectedProtocol = addressProtocols.find(
        (protocol) =>
          protocol.value === verificationStore.wallet?.walletProtocol
      )

      if (selectedProtocol) {
        methods.setValue('wallet.walletProtocol', selectedProtocol)
      }
    }

    if (verificationStore.wallet?.receivingWalletProtocol) {
      const selectedReceivingProtocol = addressProtocols.find(
        (protocol) =>
          protocol.value === verificationStore.wallet?.receivingWalletProtocol
      )

      if (selectedReceivingProtocol) {
        methods.setValue(
          'wallet.receivingWalletProtocol',
          selectedReceivingProtocol
        )
      }
    }
  }, [verificationStore.wallet])

  const onSubmit: SubmitHandler<VerificationStepEntity> = async (data) => {
    setLoading(true)

    if (
      data.wallet?.receivingWalletProtocol &&
      typeof data.wallet?.receivingWalletProtocol === 'object'
    ) {
      data.wallet.receivingWalletProtocol = (
        data.wallet?.receivingWalletProtocol as {
          label: string
          value: string
        }
      ).value
    }

    if (
      data.wallet?.walletProtocol &&
      typeof data.wallet?.walletProtocol === 'object'
    ) {
      data.wallet.walletProtocol = (
        data.wallet?.walletProtocol as {
          label: string
          value: string
        }
      ).value
    }

    await verificationStore.saveWallet(data)
    setLoading(false)
    navigateBack()
  }
  const navigateBack = (): void => {
    checkRedoNavigate(navigate, -1)
  }

  const isAlreadySent = (): boolean => {
    const currentUser = userStore.user
    if (
      currentUser?.status &&
      (currentUser.status === VerificationStepStatus.REJECTED ||
        currentUser.status === VerificationStepStatus.COMPLETED)
    ) {
      return true
    }

    return false
  }

  const isSent = isAlreadySent()

  return (
    <CardScrollable
      title="Wallet Check"
      loading={loading}
      formMethods={methods}
      formOnSubmit={onPromise(methods.handleSubmit(onSubmit))}
      secondaryBtn={{
        onClick: navigateBack
      }}
      primaryBtn={{
        type: 'submit',
        title: 'Save',
        hide: isSent
      }}
      information="Please enter the wallet address from which the funds will be sent below."
    >
      <div className="flex flex-col md:flex-row md:gap-2 w-full">
        {configStore.config?.verificationStepsConfig?.wallet
          ?.receivingWallet && (
          <DropdownSelected
            variable="wallet.walletProtocol"
            title="Sending Wallet Protocol"
            options={addressProtocols}
            disabled={true}
            className="w-full md:max-w-[250px]"
          />
        )}

        <TextInput
          variable="wallet.walletAddress"
          title={
            configStore.config?.verificationStepsConfig?.wallet?.receivingWallet
              ? 'Sending Wallet Address'
              : 'Wallet Address'
          }
          optionType={InputOptionType.TEXT_AND_NUMBER}
          className="w-full"
          wrapperClasses="w-full"
          disabled={
            isSent ||
            configStore.config?.verificationStepsConfig?.wallet?.receivingWallet
          }
          loading={loading}
        />
      </div>

      {configStore.config?.verificationStepsConfig?.wallet?.receivingWallet && (
        <div className="flex flex-col md:flex-row md:gap-2 w-full">
          <DropdownSelected
            variable="wallet.receivingWalletProtocol"
            title="Receiving Wallet Protocol"
            options={addressProtocols}
            required={true}
            isSearchable={true}
            className="w-full md:max-w-[250px]"
          />
          <TextInput
            variable="wallet.receivingWalletAddress"
            title="Receiving Wallet Address"
            optionType={InputOptionType.TEXT_AND_NUMBER}
            className="w-full"
            wrapperClasses="w-full"
            disabled={isSent}
            loading={loading}
          />
        </div>
      )}
    </CardScrollable>
  )
}

export default observer(Wallet)
