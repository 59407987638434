import { NavigateFunction } from 'react-router-dom'

const checkRedoNavigate = (navigate: NavigateFunction, to: any): void => {
  console.log('checkRedoNavigate')
  if (location.search?.includes('fromLink')) {
    console.log('verification-process')
    navigate('/verification-process')
  } else if (typeof to === 'number' && to < 0 && !history.state) {
    console.log('typeof to === number && to < 0 && !history.state')
    navigate('/verification-process')
  } else {
    console.log('to', to)
    navigate(to)
  }
}

export { checkRedoNavigate }
