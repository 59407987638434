import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { VerificationStepStatus } from '../../../../enums/VerificationStepStatus'
import { VerificationStore } from '../../../../stores/verificaton.store'
import CardScrollable from '../../../CardScrollable/CardScrollable'
import { checkRedoNavigate } from '../../../../util/navigateHelper'
import userStore from '../../../../stores/user.store'
import User from '../../../../dto/User.interface'
import PersonalInformationForm from './PersonalInformationForm'

interface Props {
  verificationStore: VerificationStore
  isDirector?: boolean
}

const PersonalInformation = ({
  verificationStore,
  isDirector = false
}: Props): JSX.Element => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const loadIntialy = async (): Promise<void> => {
      setIsLoading(true)
      await verificationStore
        .loadPersonalInformation()
        .catch((err) => console.error(err))

      await userStore.getMe().catch((err) => console.log(err))
      setIsLoading(false)
    }

    loadIntialy().catch((err) => console.error(err))
  }, [])

  const navigateBack = (): void => {
    console.log('navigateBack')
    checkRedoNavigate(navigate, -1)
  }

  const onSave = async (personalInfo: any): Promise<void> => {
    try {
      // await onSubmit(data.personalInfo)
      setIsLoading(true)
      await verificationStore.updatePersonalInformation(personalInfo)
      setIsLoading(false)
      console.log('save successful')
      navigateBack()
    } catch (error) {
      console.error(error)
    }
  }

  const isAlreadySent = (): boolean => {
    let user: User | undefined
    if (isDirector) {
      if (!userStore.isUserDirector()) {
        return true
      }
      user = userStore.user?.directorApproval?.director
    } else {
      user = userStore.user
    }
    if (
      (user?.status &&
        (user.status === VerificationStepStatus.REJECTED ||
          user.status === VerificationStepStatus.COMPLETED)) ??
      user?.verifiedData
    ) {
      return true
    }

    return false
  }

  const isSent = isAlreadySent()
  return (
    <CardScrollable title="Personal Information" loading={isLoading}>
      <PersonalInformationForm
        personalInfo={verificationStore.personalInformation}
        isSent={isSent}
        navigateBack={navigateBack}
        save={(personalInfo: any) => {
          onSave(personalInfo).catch((err) => console.error(err))
        }}
      />
    </CardScrollable>
  )
}

export default PersonalInformation
