/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/space-before-function-paren */
import { getMe, registerOrLogin, verifyToken } from '../api/authAPI'
import User from '../dto/User.interface'
import { NavigateFunction } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import { notifyError, notifySuccess } from '../util/notifications'
import LoginDto from '../dto/Login.dto'
import { makeObservable, observable } from 'mobx'
import { finishUser, generateFinishCode } from '../api/userAPI'

export class UserStore {
  @observable user: User | undefined
  @observable isRedirected: boolean = false

  constructor() {
    makeObservable(this)
  }

  getMe = async (): Promise<void> => {
    try {
      const response = await getMe()
      if (response?.status === 200) {
        this.user = response.data
        if (this.user && this.user.verifiedData) {
          window.location.href = '/finished'
        }
      } else {
        this.resetLocalStorage()
      }
    } catch (error) {
      this.resetLocalStorage()
    }
  }

  resetLocalStorage = (): void => {
    localStorage.removeItem('crypto_token')
    window.location.href = '/'
  }

  getUser = (): User | undefined => {
    if (this.user && this.user.id) {
      return this.user
    }

    const cryptoUser = localStorage.getItem('crypto_user')
    if (!cryptoUser) {
      return undefined
    }

    this.user = JSON.parse(cryptoUser)
    return this.user
  }

  registerOrLoginUser = async (args: {
    data: LoginDto
    navigate?: NavigateFunction
    isResend?: boolean
  }): Promise<void> => {
    const response: AxiosResponse<any, any> | undefined = await registerOrLogin(
      args.data
    )

    if (response?.status === 201) {
      notifySuccess(
        'A link was sent to the email address you entered to continue.'
      )
      if (args.isResend) {
        return
      }
      args.navigate && args.navigate('/verfication-success')
      this.user = {
        ...response.data,
        steps: []
      }
      localStorage.setItem('crypto_user', JSON.stringify(args.data))
    } else {
      notifyError('Something went wrong. Please try again.')
    }
  }

  verifyLoginOrRegistrationToken = async (args: {
    token: string
    verificationToken: string
    redirect: string
    params?: any
    navigate: NavigateFunction
    hideNotice?: boolean
  }): Promise<void> => {
    const response = await verifyToken(args.token, args.verificationToken)

    let params = ''
    for (const [key, value] of Object.entries(args.params)) {
      if (params === '') {
        params = `?${key}=${value as string}`
      } else {
        params += `&${key}=${value as string}`
      }
    }

    if (response?.status === 200) {
      const token: string = response.data.jwt
      localStorage.setItem('crypto_token', token)
      localStorage.removeItem('crypto_user')
      if(!args.hideNotice) {
        notifySuccess('You are now successfully logged in.')
      }
      if (args.redirect !== '') {
        this.isRedirected = true
        if (args.redirect.includes('officer-verification/')) {
          args.navigate('/officer-verification')
          args.navigate('/' + args.redirect)
        } else {
          args.navigate('/' + args.redirect + params)
        }
      } else {
        args.navigate('/verification-process' + params)
      }
    } else {
      notifyError('Something went wrong. Please try again.')
    }
  }

  finish = async (args: { data: any; isDirector?: boolean }): Promise<void> => {
    args.data = { ...args.data, verifiedData: true }
    await finishUser(args)
  }

  generateFinishCode = async (args?: {
    isDirector?: boolean
  }): Promise<void> => {
    await generateFinishCode(args)
  }

  isUserDirector = (directorApproval?: any): boolean => {
    if (!directorApproval) {
      directorApproval = this.user?.directorApproval
    }
    if (this.user && directorApproval && directorApproval.director) {
      if (this.user.email === directorApproval.director.email) {
        return true
      }
    }
    return false
  }
}

const userStore = new UserStore()
export default userStore
